<script>
//Importing Line class from the vue-chartjs wrapper
import { Line } from "vue-chartjs";

//Exporting this so it can be used in other components
export default {
  extends: Line,
  props: {
    basicAccelGyroData: [],
    colors: [],
  },
  data() {
    return {
      datacollection: {
        labels: [
          "27-02-2021",
          "27-02-2021",
          "27-02-2021",
          "27-02-2021",
          "27-02-2021",
          "27-02-2021",
          "27-02-2021",
          "27-02-2021",
        ],
        datasets: [
          {
            label: "usage hours",
            fill: false,
            pointBackgroundColor: this.colors[0],
            borderWidth: 1,
            borderColor: this.colors[1],
            data: [5, 6, 28, 19, 13, 24, 26, 12, 4, 28, 25, 33, 2],
          },
        ],
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        tooltips: {
          mode: "nearest",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                display: false,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: false,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.datacollection, this.options);
  },
};
</script>

<style scoped>
canvas {
  width: 100px;
  height: 140px;
}

@media (min-width: 800px) {
  canvas {
    width: 180px;
    height: 140px;
  }
}
</style>