











import { Component, Prop, Vue } from "vue-property-decorator";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import moment from "moment";

@Component({
  components: { DatePicker },
})
export default class WeekrangePicker extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  @Prop() onDateRangeChange!: Function;
  dateRange = new Date();
  updateValues(e) {
    const from_date = moment(e).startOf("week").format("DD-MM-YYYY");
    const to_date = moment(e).endOf("week").format("DD-MM-YYYY");

    this.onDateRangeChange(from_date, to_date);
  }
}
