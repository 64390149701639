<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    actualUsageData: [],
  },
  data: () => {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            label: "Actual hour",
            backgroundColor: "#0077b6",
            data: [],
            barThickness: 12,
          },
          {
            label: "Usage hour",
            backgroundColor: "#48cae4",
            data: [],
            barThickness: 12,
          },
        ],
      },
      options: {
        tooltips: {
          mode: "nearest",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.updateChartData();
  },
  watch: {
    actualUsageData: function () {
      this.updateChartData();
    },
  },
  methods: {
    updateChartData: function () {
      this.chartdata.labels = this.actualUsageData.map((x) => x.time);
      this.chartdata.datasets[0].data = this.actualUsageData.map(
        (x) => x.actualHours
      );
      this.chartdata.datasets[1].data = this.actualUsageData.map(
        (x) => x.usageHours
      );

      this.renderChart(this.chartdata, this.options);
    },
  },
};
</script>