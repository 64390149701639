









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UsageHoursC from "../charts/UsageHoursC.vue";

@Component({ components: { UsageHoursC } })
export default class UsageChartModal extends Vue {
  @Prop() basicDetailData!: any;
  @Prop() showModal = false;
  @Prop() onHideModal!: Function;

  @Watch("showModal")
  watchshowModal() {
    if (this.showModal) {
      this.fetchEventDetail(this.usageChartPicker);
    }
  }

  async fetchEventDetail(picker: string) {
    try {
      const url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-c-usage-actual?${picker}=true`;

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        this.basicDetailData = await response.json();
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  usageChartPicker = "daily";

  @Watch("usageChartPicker")
  watchusageChartPicker() {
    this.fetchEventDetail(this.usageChartPicker);
  }
}
