




























































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  RefreshCcwIcon,
  ClockIcon,
  ChevronUpIcon,
  UserIcon,
  MonitorIcon,
  UnlockIcon,
  LockIcon,
  ToolIcon,
  ThumbsDownIcon,
} from "vue-feather-icons";
import BarChart from "../components/charts/BarChart.vue";
import UsageHours from "../components/charts/UsageHours.vue";

import "leaflet/dist/leaflet.css";
// import "leaflet-search/src/leaflet-search.css";
import L from "leaflet";
// import LeafletSearch from "leaflet-search";

import Loader from "../components/ui/Loader.vue";
import moment from "moment";

import MapSearch from "../components/ui/MapSearch.vue";
import ActualChartModal from "../components/Dashboard/ActualChartModal.vue";
import UsageChartModal from "../components/Dashboard/UsageChartModal.vue";

import WeekrangePicker from "../components/ui/Weekrange-Picker.vue";

@Component({
  components: {
    RefreshCcwIcon,
    ClockIcon,
    ChevronUpIcon,
    UserIcon,
    MonitorIcon,
    BarChart,
    Loader,

    UsageHours,
    MapSearch,
    ActualChartModal,
    UsageChartModal,

    WeekrangePicker,
    UnlockIcon,
    LockIcon,
    ToolIcon,
    ThumbsDownIcon,
  },
})
export default class Dashboard extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  showActualChartModal = false;
  // actualChartPicker = "week";
  // @Watch("actualChartPicker")
  // watchactualChartPicker() {
  //   this.fetchAllActualUsage();
  // }
  usageHoursPicker = "month";
  @Watch("usageHoursPicker")
  watchusageHoursPicker() {
    this.fetchDashboardCardsPicker("usage", this.usageHoursPicker);
  }

  actualHoursPicker = "month";
  @Watch("actualHoursPicker")
  watchactualHoursPicker() {
    this.fetchDashboardCardsPicker("actual", this.actualHoursPicker);
  }

  usageHourPickerData = "";
  actualHourPickerData = "";
  async fetchDashboardCardsPicker(type, picker) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/dashboard/cards?${picker}=true`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
        switch (type) {
          case "usage":
            this.usageHourPickerData = data.usageHours;
            break;
          case "actual":
            this.actualHourPickerData = data.actualHours;
            break;
        }
      } else {
        this.basicDetailData = "";
      }
    } catch (e) {
      this.basicDetailData = "";
    }
  }

  onHideActualChartModal() {
    this.showActualChartModal = false;
  }

  showUsageChartModal = false;

  onHideUsageChartModal() {
    this.showUsageChartModal = false;
  }

  assetsList: any = [];
  mounted() {
    this.fetchDashboardCards();
  }

  isLoading = false;
  basicDetailData = "";
  async fetchDashboardCards() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/dashboard/cards?month=true`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
        this.usageHourPickerData = data.usageHours;
        this.actualHourPickerData = data.actualHours;
        await this.fetchAllAssetsHistory();
        await this.fetchAllTelematicsHistory();
        await this.fetchAllActualUsage("", "");
        await this.fetchAllAssetSites();
      } else {
        this.basicDetailData = "";
      }
    } catch (e) {
      this.basicDetailData = "";
    } finally {
      this.isLoading = false;
    }
  }

  actualUsageData = "";
  onWeekRangePickerChange(from_date, to_date) {
    this.fetchAllActualUsage(from_date, to_date);
  }
  async fetchAllActualUsage(from_date, to_date) {
    try {
      // this.isLoading = true;
      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/dashboard/usage-actual`;
      if (from_date && to_date) {
        url += `?startDate=${from_date}&endDate=${to_date}`;
      }
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      if (response.status == 200) {
        const data = await response.json();
        this.actualUsageData = data;
      }
    } catch (e) {
      console.log(e);
    }
  }

  assetsHistoryList = "";
  async fetchAllAssetsHistory() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/history?take=4`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        if (data.history) {
          this.assetsHistoryList = data.history;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  telematicsHistoryList = "";
  async fetchAllTelematicsHistory() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/history?take=4`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        if (data.assets) {
          this.telematicsHistoryList = data.assets;
        }
        // if (data) {
        //   this.telematicsHistoryList = data.reverse();
        // }
      }
    } catch (e) {
      console.log(e);
    }
  }

  reloadData() {
    this.fetchDashboardCards();
  }

  access_token =
    "pk.eyJ1Ijoia3VzaGFua3VyeGQiLCJhIjoiY2s1MXZvbHAxMGZoaDNmcGwzdXo4ZHgwNSJ9.kqBU5Wrn2yMM1RXbK3T9Gg";
  center: any = [37.7749, -122.4194];

  mapDiv: any = null;
  initLeafletMap() {
    this.mapDiv = L.map("mapContainer").setView(this.center, 13);

    this.watchThemeChange();

    // const searchLayer = L.layerGroup().addTo(this.mapDiv);

    // this.mapDiv.addControl(
    //   new LeafletSearch({
    //     layer: searchLayer,
    //     filterData: (textSearch) => {
    //       this.searchLocation(textSearch);
    //     },
    //     position: "topright",
    //   })
    // );
  }

  onSearchSubmit(searchText) {
    this.searchLocation(searchText);
  }

  onSearchSuggestionSubmit(data) {
    this.addLocationMarkers(data);
  }

  async searchLocation(query) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/utils/map?search=${query}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const data = await response.json();
        if (data.length > 0) {
          this.addLocationMarkers(data[0]);
        } else {
          this.$store.commit("showToastMessage", {
            boldText: "Error!",
            text: "Location not found, Try using pin-code",
            type: "Danger",
          });
        }
      } else {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: "Something went wrong!",
          type: "Danger",
        });
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  locationMarker: any = null;
  addLocationMarkers(asset) {
    if (asset.latitude === null || asset.longitude === null) {
      return;
    }

    if (this.mapDiv === null) {
      this.initLeafletMap();
    }
    const myIcon = L.icon({
      iconUrl: require("../assets/location-pin.svg"),
      iconSize: [38, 95],
      iconAnchor: [22, 66],
      popupAnchor: [-4, -30],
    });

    if (this.locationMarker !== null) {
      this.mapDiv.removeLayer(this.locationMarker);
    }

    this.locationMarker = L.marker([asset.latitude, asset.longitude], {
      icon: myIcon,
    })
      .bindPopup(
        `<p class='font-bold'>${asset.city || "-"}<br/> ${
          asset.state || "-"
        } , ${asset.country || "-"}</p>`
      )
      .openPopup();

    this.mapDiv.addLayer(this.locationMarker);
    this.mapDiv.flyTo([asset.latitude, asset.longitude], 16.0);
  }

  @Watch("$store.state.themeAT")
  watchThemeChange() {
    L.tileLayer(
      `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${this.access_token}`,
      {
        attribution: "Durbin",
        maxZoom: 18,
        id:
          this.$store.state.themeAT === "dark"
            ? "mapbox/dark-v10"
            : "mapbox/light-v10",
        accessToken: this.access_token,
      }
    ).addTo(this.mapDiv);
  }

  async fetchAllAssetSites() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/list-lat-long`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            lat_tl: 0,
            long_tl: 0,
            lat_br: 180,
            long_br: 180,
          }),
        }
      );

      if (response.status == 200) {
        this.assetsList = await response.json();
        for (let i = 0; i < this.assetsList.length; i++) {
          const assetStatus = await this.fetchAssetStatus(
            this.assetsList[i].id
          );
          this.assetsList[i] = { ...this.assetsList[i], status: assetStatus };
        }
        this.assetsList = this.assetsList.sort((a,b) => {
          const x = a.status == 'active' ? 1 : 0;
          const y = b.status == 'active' ? 1 : 0;
          return y - x;
        });
        if (this.assetsList.length > 0) {
          setTimeout(() => {
            this.assetsList.map((x: any) => this.addMyMarkerToMap(x));
          }, 10);
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  async fetchAssetStatus(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/device-status/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const status = await response.json();
        return status.status;
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  selectedMarker: any = null;
  popup: any = null;
  locationBoundArray: any = [];
  addMyMarkerToMap(asset: any) {
    if (asset.site.latitude === null || asset.site.longitude === null) {
      return;
    }

    if (this.mapDiv === null) {
      this.initLeafletMap();
    }
    const myIcon = L.icon({
      iconUrl: require("../assets/map-pin.svg"),
      iconSize: [38, 95],
      iconAnchor: [22, 66],
      popupAnchor: [-4, -30],
    });

    this.selectedMarker = L.marker(
      [asset.site.latitude, asset.site.longitude],
      {
        icon: myIcon,
      }
    )
      .bindPopup(
        `<p class='font-bold'>Satus: <span class='font-medium ${
          asset.status === "active" ? "text-theme-9" : "text-theme-6"
        }'>${
          asset.status || " -- "
        }</span><br /><br />Location:  <span class='font-medium'> ${
          asset.site.address
        }, ${asset.site.city}, ${asset.site.state}, ${
          asset.site.country
        } </span></p> `
      )
      .openPopup();

    this.mapDiv.addLayer(this.selectedMarker);
    this.locationBoundArray.push([asset.site.latitude, asset.site.longitude]);
    this.mapDiv.fitBounds(this.locationBoundArray, { padding: [84, 84] });
  }

  formatTime(hrs) {
    let formattedTime = "";
    const millisec = Number(hrs) * 60 * 60 * 1000;
    const duration = moment.duration(millisec);

    // const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours());
    // const mins = Math.floor(duration.asMinutes()) - hours * 60;

    // if (days > 0) {
    //   formattedTime = days + " days " + hours + " hrs";
    // } else if (hours > 0) {
    //   formattedTime = hours + " hrs " + mins + " mins";
    // } else if (mins > 0) {
    //   formattedTime = mins + " mins";
    // } else {
    //   formattedTime = "0";
    // }

    formattedTime = hours + " hrs";

    return formattedTime;
  }
}
